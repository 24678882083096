<template>
  <div>
    <h2>Список справочников</h2>

    <el-divider></el-divider>
    <el-link @click="reLocate('/classifiers/contractors')" type="success"
      >Справочник контрагентов</el-link
    >
    <el-divider></el-divider>
    <el-link
      @click="reLocate('/classifiers/administrative_document')"
      type="success"
      >Справочник распорядительных документов
    </el-link>
    <el-divider></el-divider>
    <el-link
      @click="reLocate('/classifiers/responsible_persons')"
      type="success"
      >Справочник ответственных лиц</el-link
    >
    <el-divider></el-divider>

    <el-link
      @click="reLocate('/classifiers/vehicles')"
      type="success"
      >Справочник транспортных средств</el-link
    >
    <el-divider></el-divider>
  </div>
</template>

<script>
export default {
  name: "classifiersMain",
  methods: {
    reLocate(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style scoped></style>
